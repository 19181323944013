@tailwind base;
@tailwind components;
@tailwind utilities;

#particles canvas{
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
}

@media (min-width: 640px) {
    .sm\:text-cm {
        font-size: 1.1rem;
        line-height: 1.75rem;
    }
}

/* App.css or index.css */
/* App.css or index.css */
@keyframes halo {
  0% {
    box-shadow: 0 0 10px rgba(255, 159, 64, 0.6), 0 0 20px rgba(255, 159, 64, 0.6), 0 0 30px rgba(255, 159, 64, 0.6);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 159, 64, 0.9), 0 0 30px rgba(255, 159, 64, 0.9), 0 0 40px rgba(255, 159, 64, 0.9);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 159, 64, 0.6), 0 0 20px rgba(255, 159, 64, 0.6), 0 0 30px rgba(255, 159, 64, 0.6);
  }
}

.halo-effect {
  animation: halo 2s infinite alternate;
}



::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #eeb774;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #f2a129;
}